@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&family=Mulish:wght@400;700&display=swap");
html,
body,
#root {
  height: 100%;
}

html {
  font-size: 62.5%;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: "Inter", sans-serif;
  color: #111111;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}

h1,
h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: clamp(2.4rem, 3vw + 1rem, 3.6rem);
}

h3 {
  font-size: 1.6rem;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}

html {
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body,
div,
span,
object,
iframe,
figure,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}

input,
textarea {
  width: 100%;
  display: block;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  margin: 0;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}

.btn {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  background: #111111;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  padding-block: 1rem;
  max-width: 25rem;
  border-radius: 2rem;
  border: 2px solid #111111;
  cursor: pointer;
}
.btn:hover {
  background: transparent;
  color: #111111;
}
.btn:disabled {
  cursor: not-allowed;
}
.btn:disabled:hover {
  background: #111111;
  color: white;
}

.btn--sm {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  max-width: 18rem;
  padding-block: 0.7rem;
  font-size: 1.6rem;
  border-radius: 2rem;
  cursor: pointer;
  background: #111111;
  color: white;
  border: 2px solid #111111;
  font-weight: 500;
}
.btn--sm:hover {
  background: transparent;
  color: #111111;
}
.btn--sm:disabled {
  cursor: not-allowed;
}
.btn--sm:disabled:hover {
  background: #111111;
  color: white;
}

.custom-btn {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  cursor: pointer;
  background: transparent;
  letter-spacing: 0.05em !important;
  font-size: 1.8rem;
  font-weight: 600;
}

.mobile-footer {
  color: white;
  font-weight: 500;
  text-align: center;
  padding-bottom: 3rem;
}

.mobile-footer__link {
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 800px) {
  .mobile-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.8rem;
  }
}
.navbar {
  color: #b8b8b8;
  background: #111111;
  padding: 2.2rem 5.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navbar .navbar__logo {
  cursor: pointer;
  max-width: 8.5rem;
}
.navbar .navbar__mobile {
  display: none;
}
.navbar .navbar__mobile .navbar__mobile-menu {
  display: none;
}
.navbar .navbar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 3.6rem;
}
.navbar .navbar__wrapper .navbar__link {
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar__wrapper .navbar__link:hover {
  color: white;
}
.navbar .navbar__wrapper .navbar__notification-icon {
  cursor: pointer;
}
.navbar .navbar__wrapper .navbar__profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.8rem;
}
.navbar .navbar__wrapper .navbar__profile-wrapper .navbar__profile-img {
  cursor: pointer;
  border-radius: 50%;
  max-width: 5.2rem;
  aspect-ratio: 1;
}
.navbar .navbar__wrapper .navbar__profile-wrapper .navbar__profile-dropdown {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
}
.navbar .navbar__wrapper .navbar__profile-wrapper .navbar__profile-dropdown:hover {
  color: white;
}
.navbar .navbar__wrapper .navbar__profile-wrapper .navbar__profile-dropdown .navbar__logout {
  position: absolute;
  right: 0;
  bottom: -4.5rem;
  background: #f2f2f2;
  padding: 0.2rem 0.5rem;
}
.navbar .navbar__wrapper .navbar__profile-wrapper .navbar__profile-dropdown .navbar__logout .navbar__logout-text {
  white-space: nowrap;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  font-size: 1.6rem;
  color: #111;
}

@media (max-width: 800px) {
  .navbar {
    z-index: 1;
    padding-inline: 2.8rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }
  .navbar .navbar__logo {
    display: none;
  }
  .navbar .navbar__mobile {
    z-index: 1;
    position: relative;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .navbar .navbar__mobile .navbar__mobile-menu {
    position: absolute;
    bottom: -11.5rem;
    left: -2.8rem;
    width: 27rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    list-style: none;
    padding: 2.5rem;
    margin: 0;
    background: #111;
    -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.25));
    font-size: 2rem;
    color: white;
    border-bottom-right-radius: 3rem;
  }
  .navbar .navbar__mobile .navbar__mobile-menu::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    background: #111111;
    top: -4px;
    left: 0;
  }
  .navbar .navbar__mobile .navbar__mobile-menu .navbar__mobile-menu-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
  }
  .navbar .navbar__wrapper {
    display: none;
  }
}
.footer {
  color: white;
  background: url("../assets/images/footer_bg.png") no-repeat center center/cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1.5rem 6rem;
  font-size: 1rem;
  font-weight: 700;
}
.footer .footer__copyright .new-line {
  display: none;
}
.footer .footer__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}

@media (max-width: 800px) {
  .footer {
    background: transparent;
    color: #111111;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 0 2.5rem;
  }
  .footer .footer__copyright {
    padding: 1rem 0 2rem 0.8rem;
    border-top: 1px solid #111111;
  }
  .footer .footer__copyright .new-line {
    display: block;
  }
  .footer .footer__wrapper svg path {
    fill: #111111;
  }
  .footer .footer__wrapper svg rect {
    display: none;
  }
}
.menu__container {
  color: #111111;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: -12rem;
  right: -8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 1rem;
}
.menu__container:hover {
  color: #111111;
}
.menu__container .menu__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.menu__container .menu__option .menu__option-text {
  font-size: 1rem;
}

.confirm .confirm__container {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 36.8rem;
  padding: 1rem 3.7rem 2rem 3.7rem;
  background: #f2f2f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4.4rem;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
  border-radius: 25px;
}
.confirm .confirm__container .confirm__top-text {
  font-size: 1rem;
  color: #b8b8b8;
  text-align: start;
}
.confirm .confirm__container .confirm__text {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.3;
  font-size: 1.6rem;
  text-align: center;
}
.confirm .confirm__container .confirm__buttons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2.5rem;
}
.confirm .confirm__container .confirm__buttons-wrapper .btn--sm {
  padding: 0.3rem 2.5rem;
}
.confirm .confirm__container .confirm__buttons-wrapper .btn--sm:last-of-type {
  background: #f2f2f2;
  color: #111111;
  border: 1px solid var(--black, #111);
}

.furniture .furniture__container {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 54rem;
  max-height: 72.3rem;
  padding: 4.4rem 1.5rem 2.2rem 1.5rem;
  background: #f2f2f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4.4rem;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
}
.furniture .furniture__container .close-btn {
  cursor: pointer;
  position: absolute;
  font-size: 3.2rem;
  top: 0;
  right: 1.2rem;
}
.furniture .furniture__container .furniture__inner-container {
  background: white;
  padding: 3.6rem;
}
.furniture .furniture__container .furniture__inner-container .furniture__text {
  font-size: 1.6rem;
}
.furniture .furniture__container .furniture__inner-container img {
  width: 36.8rem;
  margin-left: 3rem;
}
.furniture .furniture__container .furniture__inner-container .furniture__inputs-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.furniture .furniture__container .furniture__inner-container .furniture__inputs-wrapper .furniture__input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
}
.furniture .furniture__container .furniture__inner-container .furniture__inputs-wrapper .furniture__input-wrapper input {
  cursor: pointer;
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.furniture .furniture__container .furniture__inner-container .furniture__inputs-wrapper .furniture__input-wrapper input:checked {
  background-color: black;
}
.furniture .furniture__container .furniture__inner-container .furniture__rotate-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.furniture .furniture__container .btn {
  margin: 0 auto;
}

.furniture-list .furniture-list__container {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 54rem;
  max-height: 72.3rem;
  padding: 4.4rem 1rem;
  background: #f2f2f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  gap: 4.4rem;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
}
.furniture-list .furniture-list__container::-webkit-scrollbar {
  display: none;
}
.furniture-list .furniture-list__container .close-btn {
  cursor: pointer;
  position: absolute;
  font-size: 3.2rem;
  top: 0;
  right: 0.8rem;
}
.furniture-list .furniture-list__container img {
  cursor: pointer;
  max-width: 13.4rem;
  background-color: #f2f2f2;
}
.furniture-list .furniture-list__container .furniture-list__outer-wrapper {
  background: white;
  padding: 1rem 1.5rem;
}
.furniture-list .furniture-list__container .furniture-list__outer-wrapper .furniture-list__inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: scroll;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 0.5rem;
}
.furniture-list .furniture-list__container .furniture-list__outer-wrapper .furniture-list__inner-wrapper::-webkit-scrollbar {
  display: none;
}
.furniture-list .btn {
  position: absolute;
  bottom: 18%;
  left: 41.5%;
}

.sign-up {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: url("../assets/images/bgBlur.svg") no-repeat center center/cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
}
.sign-up .sign-up__logo {
  display: none;
}
.sign-up .sign-up__outer-container {
  margin-top: 5rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: calc(100% - 2.5rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sign-up .sign-up__outer-container .sign-up__desktop-title {
  display: none;
}
.sign-up .sign-up__outer-container .sign-up__mobile-title {
  color: white;
  font-family: Source Sans Pro;
  font-size: 3.2rem;
}
.sign-up .sign-up__outer-container .sign-up__inner-container {
  min-width: 100%;
  padding-block: 3rem;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form {
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2.5rem;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper {
  position: relative;
  width: min(31rem, 100%);
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__input-label {
  color: rgba(255, 255, 255, 0.7);
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__input {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-radius: 0.5rem;
  border: 0;
  padding: 0.6rem 1rem;
  letter-spacing: 0.1em;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__input::-ms-reveal {
  display: none;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__eye-icon {
  position: absolute;
  top: 2.95rem;
  right: 1.1rem;
  cursor: pointer;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__input--error_clr {
  color: #e41d1d;
}
.sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__validation-error {
  position: absolute;
  top: 5.5rem;
  color: #e41d1d;
  font-size: 1rem;
}
.sign-up .sign-up__outer-container .sign-up__inner-container .btn {
  width: 100%;
}
.sign-up .sign-up__outer-container .sign-up__inner-container .btn:disabled {
  opacity: 0.5;
}
.sign-up .sign-up__outer-container .sign-up__google-wrapper {
  color: white;
  text-align: center;
}
.sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text {
  padding-bottom: 1rem;
}
.sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-icon {
  max-width: 2.2rem;
}

@media (min-width: 800px) {
  .sign-up .sign-up__logo {
    display: block;
    max-width: 8.5rem;
    position: absolute;
    inset: 2.325rem 0 0 5.5rem;
  }
  .sign-up .sign-up__outer-container .sign-up__mobile-title {
    display: none;
  }
  .sign-up .sign-up__outer-container .sign-up__desktop-title {
    color: white;
    display: block;
    font-family: "Inter", sans-serif;
    font-size: clamp(3.6rem, 4.5vw + 1rem, 5rem);
    padding-bottom: 0.7rem;
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container {
    min-width: auto;
    width: min(58.6rem, 100%);
    background: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 4rem;
    padding: 2.6rem 3.2rem;
    margin-bottom: 5.2rem;
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container form {
    gap: 1.7rem;
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper {
    width: min(52rem, 100%);
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__input-label {
    display: block;
    color: rgba(15, 15, 15, 0.5);
    padding: 0 0 0.4rem 2.3rem;
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__eye-icon {
    top: 3.4rem;
  }
  .sign-up .sign-up__outer-container .sign-up__inner-container form .sign-up__input-wrapper .sign-up__validation-error {
    top: 6rem;
  }
  .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text {
    padding-bottom: 0;
    position: relative;
  }
  .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text::before, .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text::after {
    content: "";
    position: absolute;
    width: 16.8rem;
    height: 1px;
    border-bottom: 1px solid white;
  }
  .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text::before {
    left: -135%;
    bottom: 50%;
  }
  .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-text::after {
    right: -135%;
    bottom: 50%;
  }
  .sign-up .sign-up__outer-container .sign-up__google-wrapper .sign-up__google-icon {
    max-width: 4.8rem;
    padding-block: 3.1rem;
  }
}
.homepage {
  height: 100%;
}

.homepage__container {
  color: #111111;
  background: white;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-block: 6.5rem 10.8rem;
}
.homepage__container .homepage__welcome-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: minmax(43.3rem, 53.8rem) 49.4rem;
  place-items: center;
  gap: 3rem;
  margin: 0 auto;
  margin-bottom: 19.5rem;
}
.homepage__container .homepage__welcome-container:nth-child(2) {
  margin-bottom: 6rem;
}
.homepage__container .homepage__welcome-container.welcome-grid-2 {
  grid-template-columns: 49.4rem minmax(43.3rem, 53.8rem);
}
.homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-img {
  margin-right: auto;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-img-wrapper-mobile {
  display: none;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-section-title {
  position: absolute;
  top: 0;
  left: -9rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-section-title::before {
  content: "⸺ ";
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-body {
  text-align: start;
  max-width: 28ch;
  margin-bottom: 6.5rem;
  font-size: 2.4rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-title2 {
  text-align: end;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-body2 {
  min-width: 100%;
  text-align: start;
  font-size: 2.4rem;
  margin-bottom: auto;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .custom-btn {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin-left: auto;
  margin-right: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-wrapper .custom-btn:hover {
  margin-right: 0;
  gap: 1rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-img-desktop {
  max-width: 49.4rem;
}
.homepage__container .homepage__welcome-container .homepage__welcome-img {
  height: 100%;
}
.homepage__container .homepage__welcome-container .homepage__welcome-img-mobile {
  display: none;
}
.homepage__container .homepage__steps-container .homepage__steps-title {
  text-align: center;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container {
  margin-bottom: 6rem;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container {
  min-width: 100%;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 59.8rem 30.6rem;
  place-items: center;
  gap: 7.6rem;
  padding-block: 4rem;
  margin: 0 auto;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 {
  grid-template-columns: 30.6rem 59.8rem;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 *:last-child {
  grid-row: 1;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 .homepage__step-wrapper {
  text-align: start;
  border-top: 1px solid #111111;
  border-left: 1px solid #111111;
  border-bottom: 1px solid #111111;
  border-right: 0;
  padding: 3rem 0 3rem 7rem;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 .homepage__step-wrapper .homepage__step-title {
  text-align: end;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 .homepage__step-wrapper .homepage__step-body {
  width: 90%;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container.step-grid-2 .homepage__step-wrapper .homepage__step-count {
  right: 0;
  padding-right: 0;
  padding-left: 4rem;
  background: white;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper {
  position: relative;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 3rem 10rem 3rem 0;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper .homepage__step-count {
  position: absolute;
  background: #111111;
  top: -2.5rem;
  padding-right: 4rem;
  font-size: 3rem;
}
.homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper .homepage__step-body {
  font-size: 3rem;
  line-height: 2.9rem;
}
.homepage__container .homepage__video-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  max-width: 98rem;
  margin: 0 auto;
  margin-bottom: 6rem;
}
.homepage__container .homepage__video-container::after {
  content: "";
  position: absolute;
  height: 90%;
  width: 100%;
  top: 10%;
  left: 0;
  background: rgba(223, 223, 223, 0.3);
}
.homepage__container .homepage__video-container .homepage__video-title {
  text-align: center;
}
.homepage__container .homepage__video-container .homepage__video {
  min-width: 90%;
}
.homepage__container .homepage__video-container .homepage__play-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.homepage__container .homepage__gallery-container {
  max-width: 98rem;
  margin: 0 auto;
}
.homepage__container .homepage__gallery-container .homepage__gallery-title {
  text-align: center;
  padding-bottom: 2rem;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-1 {
  display: grid;
  grid-template-columns: 39.6rem 18.8rem 35.6rem;
  grid-template-rows: repeat(2, 18.8rem);
  gap: 2rem;
  margin-bottom: 2rem;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-1 .homepage__gallery-img:nth-child(3) {
  grid-column: 2;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-1 .homepage__gallery-img:nth-child(4) {
  grid-column: 3/4;
  grid-row: 1/2;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-2 {
  display: grid;
  grid-template-columns: 29.2rem 39.6rem 25.2rem;
  grid-template-rows: repeat(2, 18.8rem);
  gap: 2rem;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-2 .homepage__gallery-img:nth-child(2) {
  grid-column: 1/2;
  grid-row: 2/3;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-2 .homepage__gallery-img:nth-child(3) {
  grid-column: 2;
  grid-row: 1/2;
}
.homepage__container .homepage__gallery-container .homepage__gallery-grid-2 .homepage__gallery-img:nth-child(5) {
  grid-column: 3;
  grid-row: 2/3;
}

@media (max-width: 1200px) {
  .homepage__container .homepage__welcome-container {
    width: calc(100% - clamp(2.4rem, 10vw + 1rem, 10rem));
    margin-bottom: 0;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-section-title {
    position: absolute;
    top: 105%;
    left: 68%;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-body {
    max-width: 100%;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper .custom-btn {
    margin: 0;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-img {
    margin-right: 0;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .custom-btn {
    margin-inline: 0 auto;
  }
  .homepage__container .homepage__welcome-container:first-of-type {
    margin-bottom: 4rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container {
    max-width: 70%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 992px) {
  .homepage__container {
    margin: 0;
    padding-block: 0;
    padding-top: 3rem;
    position: relative;
  }
  .homepage__container .homepage__welcome-container {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    gap: 5rem;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-img {
    display: none;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper {
    padding: 0;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-img-wrapper-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.5rem;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-img-wrapper-mobile .homepage__welcome-img {
    display: block;
    max-width: 33%;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-section-title {
    font-size: clamp(1rem, 1.5vw + 0.5rem, 1.6rem);
    top: 7.5rem;
    left: 45rem;
  }
}
@media (max-width: 992px) and (max-width: 695px) {
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-section-title {
    top: 12%;
    left: 65%;
  }
}
@media (max-width: 992px) {
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-title2 {
    margin-left: auto;
    padding-right: 2.5rem;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .homepage__welcome-body2 {
    font-size: clamp(1.4rem, 3vw + 0.5rem, 2.4rem);
    padding-inline: 2.5rem;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .custom-btn {
    font-size: clamp(1rem, 2vw + 0.5rem, 1.8rem);
    margin: 0;
    margin-left: auto;
    padding-right: 2.5rem;
  }
  .homepage__container .homepage__welcome-container.welcome-grid-2 .homepage__welcome-wrapper .custom-btn::after {
    padding-bottom: clamp(0px, 0.2vw, 3px);
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-img-desktop {
    display: none;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-img-mobile {
    display: block;
    margin-left: auto;
    max-width: 80%;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper {
    padding-inline: 3rem;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper .homepage__welcome-body {
    font-size: clamp(1.4rem, 2vw + 0.5rem, 2.4rem);
    max-width: 100%;
    margin-bottom: 2rem;
  }
  .homepage__container .homepage__welcome-container .homepage__welcome-wrapper .btn {
    max-width: 18rem;
    padding-block: 0.7rem;
    font-size: 1.6rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .step-grid-2.homepage__step-inner-container {
    padding-block: 1.6rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .step-grid-2.homepage__step-inner-container .homepage__step-wrapper {
    padding: 0.7rem 0 0.7rem 1.4rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .step-grid-2.homepage__step-inner-container .homepage__step-wrapper .homepage__step-count {
    padding-left: 0.5rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container {
    max-width: min(61rem, 100% - 5rem);
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper {
    padding: 0.7rem 1.4rem 0.7rem 0;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper .homepage__step-count {
    top: -1.2rem;
    font-size: clamp(1rem, 1vw + 0.5rem, 3rem);
    padding-right: 0.5rem;
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper .homepage__step-title {
    font-size: clamp(2rem, 2vw + 0.5rem, 3rem);
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-wrapper .homepage__step-body {
    font-size: clamp(1.6rem, 2vw + 0.5rem, 3rem);
  }
  .homepage__container .homepage__steps-container .homepage__step-outer-container .homepage__step-inner-container .homepage__step-img {
    display: none;
  }
  .homepage__container .homepage__video-container {
    max-width: calc(100% - 5rem);
  }
  .homepage__container .homepage__video-container:after {
    top: 20%;
    height: 75%;
  }
  .homepage__container .homepage__video-container .homepage__play-icon {
    max-width: 20%;
  }
  .homepage__container .homepage__video-container .homepage__video {
    max-width: 100%;
  }
  .homepage__container .homepage__gallery-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 2.5rem;
    padding-bottom: 8rem;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-template-columns: 41.17% 20.3% 37.17%;
    grid-template-rows: repeat(2, auto);
    gap: 1%;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-1 .homepage__gallery-img {
    max-width: 100%;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-1 .homepage__gallery-img:nth-child(1),
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-1 .homepage__gallery-img:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: -1;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-template-columns: 31.3% 40.6% 27%;
    grid-template-rows: repeat(2, auto);
    gap: 1%;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-2 .homepage__gallery-img {
    max-width: 100%;
  }
  .homepage__container .homepage__gallery-container .homepage__gallery-grid-2 .homepage__gallery-img:nth-child(3) {
    grid-row-start: 1;
    grid-row-end: -1;
  }
}
.upload__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: min(106.2rem, 100% - clamp(5rem, 10vw + 1rem, 15rem));
  margin: 0 auto;
  padding-block: 5.3rem 10.6rem;
}
.upload__container .upload__text-wrapper {
  padding-bottom: 3rem;
}
.upload__container .upload__text-wrapper .upload__title {
  padding-bottom: 1rem;
}
.upload__container .upload__text-wrapper .upload__body {
  color: #b8b8b8;
  padding-bottom: 1rem;
}
.upload__container .upload__text-wrapper .upload__body .upload__body-list {
  list-style: decimal;
  padding-left: 3rem;
}
.upload__container .upload__input-container {
  font-family: "Mulish", sans-serif;
  text-align: center;
  letter-spacing: normal;
}
.upload__container .upload__input-container .upload__input-title {
  font-family: "Mulish", sans-serif;
  padding-bottom: 1rem;
}
.upload__container .upload__input-container .upload__input-wrapper {
  cursor: pointer;
  max-width: 47.7rem;
  margin: 0 auto 1.5rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4.2rem 1.3rem;
  aspect-ratio: 2/1;
  background: #f2f2f2;
  border: 1px dashed rgba(17, 17, 17, 0.5);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.upload__container .upload__input-container .upload__input-wrapper .upload__upload-icon {
  margin-bottom: 2.1rem;
}
.upload__container .upload__input-container .upload__input-wrapper .upload__input-body {
  color: #676767;
  font-size: 1rem;
}
.upload__container .upload__input-container .upload__input-wrapper .upload__input {
  visibility: hidden;
}
.upload__container .upload__input-container .btn {
  margin-bottom: 2rem;
}
.upload__container .upload__bottom-text {
  display: block;
  font-weight: 500;
}
.upload__container .upload__bottom-text .custom-btn {
  margin: 0 auto;
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  border-bottom: 1px solid #111111;
  line-height: 1.3;
}
.upload__container .upload__bottom-text .upload__bottom-span {
  color: #b8b8b8;
}
.upload__container .upload__files-container {
  font-family: "Mulish", sans-serif;
  letter-spacing: normal;
  max-width: 47.7rem;
  margin: 0 auto;
}
.upload__container .upload__files-container .upload__files-title {
  font-family: "Mulish", sans-serif;
  margin-bottom: 1rem;
}
.upload__container .upload__files-container .upload__files-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  gap: 1.3rem;
  padding: 1.6rem 0.5rem 0.1rem 0;
  max-height: 15rem;
  overflow-y: auto;
}
.upload__container .upload__files-container .upload__files-list::-webkit-scrollbar {
  width: 0.6rem;
}
.upload__container .upload__files-container .upload__files-list::-webkit-scrollbar-track {
  border-radius: 1.2rem;
  background: #f2f2f2;
}
.upload__container .upload__files-container .upload__files-list::-webkit-scrollbar-thumb {
  width: 0.6rem;
  border-radius: 1.2rem;
  background: #a6a6a6;
}
.upload__container .upload__files-container .upload__files-list .upload__file-wrapper {
  overflow: visible;
  position: relative;
}
.upload__container .upload__files-container .upload__files-list .upload__file-wrapper .upload__error {
  position: absolute;
  top: -1.4rem;
  font-size: 0.7rem;
  color: #e41d1d;
  font-weight: 500;
  line-height: 1.8rem;
}
.upload__container .upload__files-container .upload__files-list .upload__file-wrapper .upload__file {
  min-height: 3.46rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  color: #b8b8b8;
  border: 0.5px solid #11af22;
  padding: 0.5rem;
}
.upload__container .upload__files-container .upload__files-list .upload__file-wrapper .upload__file--err-clr {
  border: 0.5px solid #e41d1d !important;
}
.upload__container .custom-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Inter", sans-serif;
  margin-top: 4.3rem;
  margin-left: auto;
  color: black;
  gap: 0.5rem;
  margin-right: 0.5rem;
}
.upload__container .custom-btn:hover {
  margin-right: 0;
  gap: 1rem;
}
.upload__container .custom-btn:disabled {
  color: #b8b8b8;
}

@media (min-width: 800px) {
  .upload__container {
    display: grid;
    max-width: calc(100% - 10rem);
  }
  .upload__container .upload__files-container .upload__files-title {
    text-align: start;
    margin-bottom: 2rem;
  }
  .upload__container .upload__files-container .upload__files-list .upload__file-wrapper .upload__file {
    font-size: 1.2rem;
  }
  .upload__container .upload__files-container .upload__files-list .upload__file-wrapper .upload__error {
    top: -1.6rem;
    font-size: 1rem;
  }
}
.preview__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  width: min(106.2rem, 100% - clamp(5rem, 10vw + 1rem, 15rem));
  padding-block: 5.3rem 10.6rem;
}
.preview__container .preview__title {
  cursor: pointer;
  margin-bottom: 2rem;
  font-size: 3.6rem;
}
.preview__container .preview__title .preview__title--gray {
  color: #b8b8b8;
  font-weight: 400;
  font-size: 2rem;
  display: inline-block;
  letter-spacing: normal;
}
.preview__container .preview__img {
  margin: 0 auto;
  margin-bottom: 4rem;
  max-width: 51.1rem;
}
.preview__container .preview__buttons-outer-wrapper {
  margin-bottom: 8.2rem;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-title {
  font-family: "Mullish", sans-serif;
  font-size: 3rem;
  margin-bottom: 5.5rem;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-inner-wrapper .btn {
  border: none;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-inner-wrapper .not-selected {
  background: #b8b8b8;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-inner-wrapper .selected {
  background-color: #111111;
}
.preview__container .preview__buttons-outer-wrapper .preview__buttons-inner-wrapper .selected:hover {
  color: white;
}
.preview__container .preview__bottom-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.preview__container .preview__bottom-navigation .custom-btn {
  margin-right: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.preview__container .preview__bottom-navigation .custom-btn:first-of-type {
  margin-right: 0;
  margin-left: 0.5rem;
}
.preview__container .preview__bottom-navigation .custom-btn:first-of-type svg {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.preview__container .preview__bottom-navigation .custom-btn:hover {
  margin: 0;
  gap: 1rem;
}

.style__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: min(101rem, 100% - clamp(5rem, 10vw + 1rem, 15rem));
  padding-block: 6.5rem 10rem;
  margin: 0 auto;
}
.style__container .style__title {
  font-size: clamp(2.4rem, 2.5vw + 1rem, 3.6rem);
  padding-bottom: clamp(2rem, 2vw + 0.5rem, 3rem);
}
.style__container .style__styles-container {
  overflow-y: auto;
  max-height: 62.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 3rem;
  padding: 1rem;
}
.style__container .style__styles-container::-webkit-scrollbar {
  width: 0.6rem;
}
.style__container .style__styles-container::-webkit-scrollbar-track {
  border-radius: 1.2rem;
  background: #f2f2f2;
}
.style__container .style__styles-container::-webkit-scrollbar-thumb {
  width: 0.6rem;
  border-radius: 1.2rem;
  background: #a6a6a6;
}
.style__container .style__styles-container .selected-style {
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
}
.style__container .style__styles-container .selected-style:hover {
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
}
.style__container .style__styles-container .selected-style.style__style:hover {
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
}
.style__container .style__styles-container .style__style {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.style__container .style__styles-container .style__style .style__style-img {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  max-width: 30.7rem;
}
.style__container .style__styles-container .style__style svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.style__container .style__styles-container .style__style .style__style-name {
  color: black;
  font-size: clamp(1.2rem, 2vw + 0.25rem, 1.6rem);
}
.style__container .style__styles-container .style__style:hover {
  border-radius: 1rem;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
}
.style__container .style__buttons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 3rem;
}
.style__container .style__buttons-wrapper .custom-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  margin-right: 0.5rem;
}
.style__container .style__buttons-wrapper .custom-btn:first-of-type {
  margin-right: 0;
  margin-left: 0.5rem;
}
.style__container .style__buttons-wrapper .custom-btn:first-of-type svg {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.style__container .style__buttons-wrapper .custom-btn:hover {
  margin: 0;
  gap: 1rem;
}
.style__container .style__buttons-wrapper .btn {
  margin: 0 auto;
}
.style__container .style__buttons-wrapper .btn[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.style__container .style__buttons-wrapper .btn:active {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  scale: 0.95;
}

@media (max-width: 921px) {
  .style__container {
    padding-block: 5.3rem 7.8rem;
  }
  .style__container .style__title {
    text-align: center;
  }
  .style__container .style__styles-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .style__container .style__buttons-wrapper {
    gap: 3rem;
  }
  .style__container .style__buttons-wrapper .custom-btn {
    font-size: 1.6rem;
  }
  .style__container .style__buttons-wrapper .btn {
    max-width: 18rem;
    padding-block: 0.7rem;
    font-size: 1.6rem;
  }
}
@media (max-width: 417px) {
  .style__container {
    padding-block: 5.3rem 7.8rem;
  }
  .style__container .style__title {
    text-align: start;
  }
}
.sent {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: url("../assets/images/bgBlur.svg") no-repeat center center/cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.sent .navbar {
  margin-bottom: 4rem;
}
.sent .sent__container {
  color: white;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.sent .sent__container .sent__inner-container {
  margin-block: 4rem;
  max-width: min(100rem, 100% - 6rem);
  display: grid;
  place-items: center;
  text-align: center;
}
.sent .sent__container .sent__inner-container .sent__title {
  font-size: clamp(3.6rem, 7vw + 1rem, 5rem);
  font-weight: 600;
  padding-bottom: 6rem;
}
.sent .sent__container .sent__inner-container .sent__body {
  font-size: clamp(2rem, 3vw + 0.25rem, 2.8rem);
  font-weight: 500;
  padding-bottom: 4rem;
}
.sent .sent__container .sent__queue-text {
  font-size: 4rem;
  font-weight: 500;
  margin-bottom: clamp(6rem, 10vw + 1rem, 12rem);
}
.sent .sent__container .sent__stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem;
  gap: 1.4rem;
}
.sent .sent__container .sent__stage:last-of-type {
  margin-bottom: 8rem;
}
.sent .sent__container .sent__stage img {
  width: 2.2rem;
}
.sent .sent__container .btn {
  border: 3px solid white;
  background: transparent;
  padding-block: 0.5rem;
}
.sent .sent__container .btn:hover {
  color: #111111;
  background-color: white;
}

.projects {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.projects .projects__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: min(97.9rem, 100% - clamp(5rem, 10vw + 1rem, 15rem));
  padding-block: 6.5rem 20rem;
  margin: 0 auto;
}
.projects .projects__container .projects__title {
  margin-bottom: 3.4rem;
}
.projects .projects__container .projects__inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.5rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  padding-bottom: 2.3rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search {
  max-width: 80%;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0.3rem 0.5rem;
  border: 0;
  height: 3rem;
  background: #f2f2f2;
  font-weight: 600;
  font-size: 1.6rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search::-webkit-input-placeholder {
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search::-moz-placeholder {
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search:-ms-input-placeholder {
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search::-ms-input-placeholder {
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__files-search::placeholder {
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__search-icon {
  position: absolute;
  right: 20.5%;
  top: 0.4rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__filter {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  letter-spacing: normal;
  font-weight: 600;
  font-size: 2rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__input-wrapper .projects__filter .projects__filter-menu {
  z-index: 10;
  width: 16.6rem;
  font-family: "Source Sans Pro", sans-serif;
  position: absolute;
  bottom: -9.5rem;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(180, 178, 178, 0.25);
          box-shadow: 2px 2px 4px 0px rgba(180, 178, 178, 0.25);
  background: white;
  font-size: 2rem;
  font-weight: 600;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 3.7rem;
  font-family: "Source Sans Pro", sans-serif;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container {
  width: 21.7rem;
  height: 18.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid black;
  padding-inline: 0.7rem;
  font-size: 1.4rem;
  letter-spacing: normal;
  cursor: pointer;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container:has(.projects__create-new-plus) {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-status--in-the-process,
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-status--done {
  text-align: end;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-status--done {
  color: #11af22;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-status--in-the-process {
  color: #483ea8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-status--error {
  color: red;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-img {
  width: 70%;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  object-fit: contain;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.3rem;
  color: #b8b8b8;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper:has(.projects__file-dots-menu) {
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper .projects__menu {
  z-index: 10;
  min-width: 11.6rem;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
  right: 0;
  bottom: 2rem;
  background-color: #fff;
  padding: 1rem 1rem;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
  font-size: 1.4rem;
  letter-spacing: normal;
  color: black;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper .projects__menu .projects__menu-option {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper .projects__menu .projects__menu-option .projects__option-icon {
  width: 2rem;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper .projects__menu .projects__menu-option .projects__option-name {
  white-space: nowrap;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__file-inner-wrapper .projects__file-date {
  color: black;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__create-new-plus {
  font-size: 4rem;
  font-weight: 600;
}
.projects .projects__container .projects__inner-container .projects__files-wrapper .projects__files-inner-wrapper .projects__file-container .projects__create-new-text {
  font-size: 2rem;
  font-weight: 600;
}

.projects__modal {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 36.8rem;
  background-color: white;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.22);
  border-radius: 2.5rem;
  padding: 1.8rem 3.8rem;
}
.projects__modal .projects__modal-caption {
  font-family: "Inter", sans-serif;
  color: #b8b8b8;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
  padding-bottom: 1.1rem;
}
.projects__modal .projects__modal-input-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  letter-spacing: normal;
  font-weight: normal;
}
.projects__modal .projects__modal-input {
  outline: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0;
  height: 3rem;
  background: #f2f2f2;
  margin-bottom: 3.2rem;
}
.projects__modal .projects__modal-new-folder-btn {
  cursor: pointer;
  color: #b8b8b8;
  font-size: 1rem;
  background: transparent;
  padding-block: 0.5rem;
}
.projects__modal .btn--sm {
  margin-left: 6.5rem;
  max-width: 16.6rem;
  padding-block: 0.2rem;
}

.edit {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  background-image: url("../assets/images/edit_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.edit .edit__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: min(106.3rem, 100% - clamp(5rem, 10vw + 1rem, 15rem));
  padding-block: 6.5rem 10rem;
  margin: 0 auto;
}
.edit .edit__container svg {
  cursor: pointer;
}
.edit .edit__container .edit__button-upper-wrapper {
  margin-block: 0.8rem 2.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.edit .edit__container .edit__button-upper-wrapper .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1.5rem;
  max-width: 11.3rem;
  font-size: 2.4rem;
  font-weight: 400;
  margin: 0;
  background: #f2f2f2;
  color: #111111;
  line-height: 1;
  border: 0;
}
.edit .edit__container .edit__button-upper-wrapper .btn:first-of-type {
  border-radius: 10px 0px 0px 10px;
}
.edit .edit__container .edit__button-upper-wrapper .btn:last-of-type {
  border-radius: 0px 10px 10px 0px;
}
.edit .edit__container .edit__button-upper-wrapper .btn--selected {
  background: #111111;
  color: white;
}
.edit .edit__container .edit__title {
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.edit .edit__container .edit__subtitle {
  font-weight: 500;
  color: #b8b8b8;
  margin-bottom: 2.5rem;
}
.edit .edit__container .edit__inner-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5.7rem;
}
.edit .edit__container .edit__inner-container img {
  z-index: 0;
  max-width: 90rem;
  height: 50rem;
  cursor: pointer;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper .edit__rotate-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper .edit__rotate-wrapper svg {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper .edit__rotate-wrapper:hover svg {
  color: #b8b8b8;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper .edit__menu {
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.edit .edit__container .edit__inner-container .edit__buttons-wrapper .edit__menu:hover {
  color: #b8b8b8;
}
.edit .edit__container .edit__bottom-text {
  font-weight: 500;
  color: #b8b8b8;
  margin-bottom: 6rem;
}
.edit .edit__container .btn {
  z-index: 0;
  padding-block: 0.7rem;
  margin: 0 auto;
}

.mirror-image .styles-module_image__2hdkJ {
  -webkit-transform: scaleY(-1);
      -ms-transform: scaleY(-1);
          transform: scaleY(-1);
}

.mobile-warning {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: url("../assets/images/bg.svg") no-repeat center center/cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mobile-warning .mobile-warning__container {
  border-radius: 33px;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  max-width: 31rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4rem;
  padding: 3.8rem 1.9rem;
}
.mobile-warning .mobile-warning__container .mobile-warning__text {
  font-family: "Inter", sans-serif;
  color: white;
  text-align: center;
  font-weight: 400;
  letter-spacing: 3px;
  font-size: 3rem;
}
.mobile-warning .mobile-warning__container svg {
  max-width: 15.7rem;
}

.grey-text {
  color: #b8b8b8;
  font-size: clamp(1.4rem, 2vw + 1rem, 3rem);
}

.first-position {
  grid-row: 1;
}

.dark-bg {
  color: #b8b8b8;
  background: #111111;
}

.light-bg {
  color: #111111;
  background: white;
}

.img-spinner {
  background-image: url('../assets/icons/spinner.svg');
  background-repeat: no-repeat;
  background-position: center;
}